import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import React from 'react';
import { Cell } from 'layout/cell';
import { Grid } from 'layout/grid';
import { EmailSentFormProps } from 'modals/sign-in/email-sent-form/email-sent-form.props';
import { StyledBackButtonContainer } from 'modals/sign-in/sign-in.styles';
import { Icon } from 'core/icon/icon';

export const EmailSentForm = (props: EmailSentFormProps) => {
  const { onGoBack } = props;

  return (
    <FlexContainer
      direction="column"
      justifyContent="space-between"
      paddingTop="9rem"
      padding={{ lg: 's5', md: 's2' }}>
      <Grid padding="unset" rowGap="s1">
        <Cell>
          <Typography
            variant="h2"
            tx="signIn:emailSent"
            align={{ md: 'center' }}
          />
        </Cell>
        <Cell>
          <Typography
            color="grey"
            tx="signIn:emailSentInstructions"
            align={{ md: 'center' }}
          />
        </Cell>
      </Grid>

      <StyledBackButtonContainer>
        <Icon
          name="arrow-left"
          color="grey"
          cursor="pointer"
          width="1.8rem"
          onClick={onGoBack}
        />
      </StyledBackButtonContainer>
    </FlexContainer>
  );
};

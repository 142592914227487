import React, { memo } from 'react';
import { SpacerProps } from 'layout/spacer/spacer.props';
import { SpacerPresets } from 'layout/spacer/spacer.presets';
import { StyledSpacer } from 'layout/spacer/spacer.styles';

export const Spacer = memo((props: SpacerProps) => {
  const objectProps = Object.assign({}, SpacerPresets, props);

  return <StyledSpacer {...objectProps} />;
});

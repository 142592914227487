import React, { useEffect, useState } from 'react';
import { EmailForm } from 'modals/sign-in/email-form';
import { PasswordForm } from 'modals/sign-in/password-form';
import { SignUpForm } from 'modals/sign-in/sign-up-form';
import { ResetForm } from 'modals/sign-in/reset-form';
import { EmailSentForm } from 'modals/sign-in/email-sent-form';
import { api, RequestResponse } from 'services/api';
import { useAuth } from 'context/auth.context';
import {
  AuthenticateRequest,
  FacebookSignInRequest,
  GoogleSignInRequest,
  UserData,
} from 'services/api/auth';
import { useModalClose } from 'context/modal.context';
import { useNotification } from 'context/notification.context';
import { trackAnalyticsEvent, trackAnalyticsPageEvent } from 'utils/analytics';
import { useRouter } from 'next/router';
import { useSession } from 'src/common/hooks';
import { User } from 'types/user.type';

type SignInStep = 'email' | 'login' | 'sign-up' | 'reset' | 'email-sent';

export interface SignInModalProps {
  source?: string;
  signUpEventName?: string;
  onClose?: (data: User) => void;
}

export const SignInModal = (props: SignInModalProps) => {
  const [{ currentEmail, campaignInfo }, dispatch] = useAuth();

  const [step, setStep] = useState<SignInStep>('email');
  const { closeLastOpened, clearModalsStack } = useModalClose();
  const { updateSessionData } = useSession();
  const { notify, showPreloader, hidePreloader } = useNotification();
  const [processing, setProcessing] = useState(false);

  const {
    query: { redirectUrl },
    push: pushRoute,
  } = useRouter();

  useEffect(() => {
    trackAnalyticsPageEvent(`Auth - ${props.source || 'Main'}`);
  }, []);

  useEffect(() => {
    return () => dispatch({ type: 'RESET_LOGIN_DATA' });
  }, []);

  const signIn = async (request: AuthenticateRequest) => {
    const {
      success,
      data,
      error,
    }: RequestResponse<UserData> = await api.auth.authenticate(request);

    if (success) {
      clearModalsStack();

      await updateSessionData({
        user: data.data,
        token: data.token,
        firebaseToken: data.firebaseToken,
      });

      if (redirectUrl) {
        if (/(http|https)/.test(String(redirectUrl))) {
          window.location.href = String(redirectUrl);
        } else {
          await pushRoute(redirectUrl as string);
        }
      }

      if (props.onClose) {
        props.onClose(data?.data);
      }
    } else {
      if (props.onClose) {
        props.onClose(null);
      }

      notify({
        message: error.message,
        isError: true,
      });
    }
  };

  const emailSignIn = async ({ email, password }) => {
    const request: AuthenticateRequest = {
      email,
      password,
      fullSignUp: true,
      ...campaignInfo,
    };

    setProcessing(true);

    await signIn(request);

    setProcessing(false);
  };

  const googleSignIn = async (googleInfo: GoogleSignInRequest) => {
    const request: AuthenticateRequest = {
      type: 'google_v2',
      googleInfo,
      ...campaignInfo,
    };

    showPreloader();

    await signIn(request);

    if (props.source) {
      trackAnalyticsEvent(`${props.source} Google Signup`);
    }

    hidePreloader();
  };

  const facebookSignIn = async (facebookInfo: FacebookSignInRequest) => {
    const request: AuthenticateRequest = {
      type: 'facebook_v2',
      facebookInfo,
      ...campaignInfo,
    };

    showPreloader();

    await signIn(request);

    if (props.source) {
      trackAnalyticsEvent(`${props.source} Google Signup`);
    }

    hidePreloader();
  };

  const onSignInError = () => {
    notify({
      messageTx: 'errors.authentication',
      isError: true,
    });
  };

  const signUp = async values => {
    const request = Object.assign({}, campaignInfo, values, {
      email: currentEmail,
      signUpEventName: props.signUpEventName,
    });

    setProcessing(true);

    const { success, data, error } = await api.auth.authenticate(request);

    setProcessing(false);

    if (success) {
      await updateSessionData({
        user: data.data,
        token: data.token,
        firebaseToken: data.firebaseToken,
      });
    } else {
      notify({
        message: error.message,
        isError: true,
      });
    }

    closeLastOpened(data?.data);

    if (props.onClose) {
      props.onClose(data?.data);
    }
  };

  const resetPassword = async ({ email }) => {
    const { success } = await api.auth.requestPasswordReset({ email } as any);

    if (success) {
      dispatch({
        type: 'SET_PROPERTIES',
        data: {
          emailSent: true,
        },
      });
    }

    setStep('email-sent');
  };

  const onEmailFormSubmit = async (email: string) => {
    setProcessing(true);

    setStep('email');

    const { data, success } = await api.auth.checkIfUserExists({ email });

    setStep(data?.userExist ? 'login' : 'sign-up');

    setProcessing(false);

    if (success) {
      dispatch({
        type: 'SET_PROPERTIES',
        data: {
          currentEmail: email,
          userExists: data.userExist,
        },
      });
    }
  };

  const onGoBack = (): void => {
    dispatch({
      type: 'SET_PROPERTIES',
      data: {
        userExists: false,
        emailSent: false,
      },
    });

    setStep('email');
  };

  const onPasswordReset = (): void => {
    setStep('reset');
  };

  switch (step) {
    case 'login':
      return (
        <PasswordForm
          email={currentEmail}
          processing={processing}
          onSubmit={emailSignIn}
          onGoBack={onGoBack}
          onPasswordReset={onPasswordReset}
        />
      );
    case 'sign-up':
      return (
        <SignUpForm
          email={currentEmail}
          processing={processing}
          onSubmit={signUp}
          onGoBack={onGoBack}
          onModalClose={closeLastOpened}
        />
      );
    case 'reset':
      return (
        <ResetForm
          email={currentEmail}
          onSubmit={resetPassword}
          onGoBack={onGoBack}
        />
      );
    case 'email-sent':
      return <EmailSentForm onGoBack={onGoBack} />;
    default:
      return (
        <EmailForm
          email={currentEmail}
          processing={processing}
          onSubmit={onEmailFormSubmit}
          onGoogleSignIn={googleSignIn}
          onFacebookSignIn={facebookSignIn}
          onSignInError={onSignInError}
          onModalClose={closeLastOpened}
        />
      );
  }
};

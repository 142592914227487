import styled, { css } from 'styled-components';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { SpacerProps } from 'layout/spacer/spacer.props';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { theme } from 'styles/theme';

export const StyledSpacer = styled.div<SpacerProps>`
  ${({ width, height, hidden }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: width,
        lookIn: theme.spacing,
        isImportant: true,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
        lookIn: theme.spacing,
        isImportant: true,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: value => (value ? 'none' : 'block'),
      },
    ];

    return css`
      ${generateStylesForResolutionAwareProps(entries)}
    `;
  }}
`;

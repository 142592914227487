import styled from 'styled-components';
import { media } from 'styles/media';

export const StyledBackButtonContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  display: none;

  ${media.lessThan('md')`
    display: block;
  `}
`;

export const StyledResetLinkContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -5rem;
  left: 0;
  right: 0;

  ${media.lessThan('md')`
    position: relative;
    bottom: unset;
    margin-top: 2rem;
  `}
`;

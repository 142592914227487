import { DividedContainerProps } from 'layout/divided-container/divided-container.props';

export const DividedContainerPresets: DividedContainerProps = {
  leftContent: null,
  leftContentProps: {
    padding: 's5',
    backgroundColor: 'cream',
  },
  rightContent: null,
  rightContentProps: {
    padding: 's5',
  },
};

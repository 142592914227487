import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { Spacer } from 'layout/spacer';
import { TextInput } from 'core/text-input';
import React from 'react';
import { Formik } from 'formik';
import { PasswordFormProps } from 'modals/sign-in/password-form/password-form.props';
import { DividedContainer } from 'layout/divided-container';
import { BoxContainer } from 'layout/box-container';
import {
  StyledBackButtonContainer,
  StyledResetLinkContainer,
} from 'modals/sign-in/sign-in.styles';
import { Icon } from 'core/icon/icon';
import { PasswordFormSchema } from 'modals/sign-in/password-form/password-form.schema';

export const PasswordForm = (props: PasswordFormProps) => {
  const { email, processing, onSubmit, onGoBack, onPasswordReset } = props;

  return (
    <DividedContainer
      leftContent={
        <>
          <Typography variant="h2" tx="signIn:titlePasswordModal" />
          <Button
            tx="signIn:back"
            variant="action"
            icon={<Icon name="arrow-left" color="grey" />}
            color="grey"
            onClick={onGoBack}
          />
        </>
      }
      leftContentProps={{
        direction: 'column',
        justifyContent: 'space-between',
        hidden: { sm: true, md: true },
      }}
      rightContent={
        <Formik
          initialValues={{ password: '' }}
          enableReinitialize={true}
          validationSchema={PasswordFormSchema}
          validateOnMount={true}
          validateOnChange={true}
          onSubmit={values => onSubmit({ email, ...values })}>
          {props => (
            <form onSubmit={props.handleSubmit}>
              <BoxContainer
                padding="unset"
                marginBottom="s2"
                hidden={{ lg: true, md: false }}>
                <Typography
                  variant="h2"
                  tx="signIn:titlePasswordModal"
                  align="center"
                />
              </BoxContainer>

              <FlexContainer
                backgroundColor={{ md: 'white' }}
                borderRadius={{ md: '6px' }}
                direction="column"
                justifyContent={{ lg: 'center', md: 'center' }}
                verticalPadding={{ md: 's4' }}
                horizontalPadding={{ md: 's2' }}>
                <Typography tx={email} />

                <Spacer height="s3" />

                <TextInput
                  name="password"
                  type="password"
                  placeholderTx="signIn:password"
                  autocomplete="new-password"
                  value={props.values.password}
                  error={props.errors.password}
                  touched={props.touched.password}
                  autoFocus={true}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Spacer height="s5" />
                <Button
                  type="submit"
                  tx="signIn:login"
                  variant="primary"
                  disabled={!props.isValid}
                  processing={processing}
                />

                <StyledResetLinkContainer>
                  <Button
                    variant="action"
                    tx="signIn:reset"
                    justifySelf="center"
                    onClick={onPasswordReset}
                  />
                </StyledResetLinkContainer>
              </FlexContainer>
              <StyledBackButtonContainer>
                <Icon
                  name="arrow-left"
                  color="grey"
                  cursor="pointer"
                  width="1.8rem"
                  onClick={onGoBack}
                />
              </StyledBackButtonContainer>
            </form>
          )}
        </Formik>
      }
      rightContentProps={{
        direction: 'column',
        justifyContent: {
          lg: 'center',
          md: 'flex-start',
        },
        paddingTop: '9rem',
        horizontalPadding: {
          lg: 's5',
          md: 's2',
        },
        backgroundColor: {
          lg: 'white',
          md: 'cream',
        },
      }}
    />
  );
};

import { EmailFormProps } from 'modals/sign-in/email-form/email-form.props';
import { BoxContainer } from 'layout/box-container';
import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { Spacer } from 'layout/spacer';
import { TextInput } from 'core/text-input';
import React from 'react';
import { Formik } from 'formik';
import { EmailFormSchema } from 'modals/sign-in/email-form/email-form.schema';
import { DividedContainer } from 'layout/divided-container';
import { Icon } from 'core/icon';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { environment } from 'config/environment';
import { createLogger } from '@unly/utils-simple-logger';

const logger = createLogger({
  label: 'authentication',
});

export const EmailForm = (props: EmailFormProps) => {
  const {
    email,
    processing,
    onSubmit,
    onGoogleSignIn,
    onFacebookSignIn,
    onSignInError,
    onModalClose,
  } = props;

  return (
    <DividedContainer
      leftContent={
        <>
          <Typography variant="h2" tx="signIn:title" />
          <div>
            <Typography
              variant="p3"
              type="span"
              tx="signIn:terms"
              color="grey"
            />
            <Typography
              variant="link"
              fontSize="1.2rem"
              type="span"
              tx="signIn:termsLink"
              color="grey"
              textStyle="bold"
              display="inline"
              href="/legal/terms"
              onClick={onModalClose}
            />
          </div>
        </>
      }
      leftContentProps={{
        direction: 'column',
        justifyContent: 'space-between',
        hidden: { sm: true, md: true },
      }}
      rightContent={
        <Formik
          initialValues={{ email }}
          enableReinitialize={true}
          validationSchema={EmailFormSchema}
          validateOnMount={true}
          validateOnChange={true}
          onSubmit={values => onSubmit(values.email)}>
          {props => (
            <form onSubmit={props.handleSubmit}>
              <BoxContainer
                padding="unset"
                marginBottom="s2"
                hidden={{ lg: true, md: false }}>
                <Typography
                  variant="h2"
                  tx="signIn:titleMobile"
                  align="center"
                />
              </BoxContainer>
              <FlexContainer
                backgroundColor={{ md: 'white' }}
                borderRadius={{ md: '6px' }}
                direction="column"
                justifyContent={{ lg: 'center', md: 'center' }}
                verticalPadding={{ md: 's4' }}
                horizontalPadding={{ md: 's2' }}>
                <FacebookLogin
                  appId={environment.FACEBOOK_APP_ID}
                  fields="first_name,last_name,name,email,link"
                  autoLoad={false}
                  callback={response => {
                    onFacebookSignIn({
                      accessToken: response.accessToken,
                      profile: {
                        facebookId: response.id,
                        email: response.email,
                        firstName: response.first_name,
                        lastName: response.last_name,
                      },
                    });
                  }}
                  render={facebookProps => (
                    <Button
                      tx="signIn:facebook"
                      color="#39579a"
                      textColor="white"
                      icon={<Icon name="facebook-black" color="white" />}
                      disabled={facebookProps.isDisabled}
                      onClick={facebookProps.onClick}
                    />
                  )}
                  onFailure={onSignInError}
                />

                <Spacer height={{ lg: 's1', md: 's2' }} />

                <GoogleLogin
                  clientId={environment.GOOGLE_CLIENT_ID}
                  autoLoad={false}
                  isSignedIn={false}
                  render={googleProps => (
                    <Button
                      tx="signIn:google"
                      color="#4285f4"
                      textColor="white"
                      icon={<Icon name="google" color="white" size="2rem" />}
                      onClick={googleProps.onClick}
                    />
                  )}
                  onSuccess={(info: any) => {
                    onGoogleSignIn({
                      accessToken: info.accessToken,
                      tokenId: info.tokenId,
                      profile: info.profileObj,
                    });
                  }}
                  onFailure={({ error, details }) => {
                    logger.error(`${error}: ${details}`);

                    if (error !== 'idpiframe_initialization_failed') {
                      onSignInError(error);
                    }
                  }}
                />
                <Spacer height="s4" />
                <TextInput
                  name="email"
                  autoFocus={true}
                  placeholderTx="signIn:email"
                  value={props.values.email}
                  error={props.errors.email}
                  touched={props.touched.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Spacer height="s4" />
                <Button
                  type="submit"
                  tx="signIn:next"
                  variant="primary"
                  disabled={!props.isValid}
                  processing={processing}
                />
              </FlexContainer>
              <BoxContainer
                marginTop="s4"
                padding="unset"
                textAlign="center"
                hidden={{ lg: true, md: false }}>
                <Typography
                  variant="p3"
                  type="span"
                  tx="signIn:terms"
                  color="grey"
                  align="center"
                />
                <Typography
                  variant="link"
                  fontSize="1.2rem"
                  type="span"
                  tx="signIn:termsLink"
                  color="grey"
                  textStyle="bold"
                  display="inline"
                  href="/legal/terms"
                  onClick={onModalClose}
                />
              </BoxContainer>
            </form>
          )}
        </Formik>
      }
      rightContentProps={{
        direction: 'column',
        paddingTop: '9rem',
        justifyContent: {
          lg: 'center',
          md: 'flex-start',
        },
        horizontalPadding: {
          lg: 's5',
          md: 's2',
        },
        backgroundColor: {
          lg: 'white',
          md: 'cream',
        },
      }}
    />
  );
};

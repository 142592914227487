import { useModal } from 'context/modal.context';
import { SignInModal } from 'modals/sign-in/sign-in';
import React from 'react';
import { User } from 'types/user.type';
import {
  ANALYTICS_IDENTIFY_REGISTERED,
  AUTH_TOKEN,
  CART_ITEMS,
  FIREBASE_TOKEN,
} from 'constants/common';
import { resetAnalyticsUser } from 'utils/analytics';
import { useAuth } from 'context/auth.context';
import { useCart } from 'context/cart.context';
import { useCookies } from 'react-cookie';
import {
  removeFromLocalStorage,
  removeFromSessionStorage,
} from 'utils/storage';
import { environment } from 'config/environment';
import { api } from 'services/api';
import { useRouter } from 'next/router';

export interface UseSignInModalProps {
  source?: string;
  signUpEventName?: string;
  onClose?: (data?: User) => void;
}

export const useSignInModal = (props?: UseSignInModalProps) => {
  const { showModal } = useModal({
    template: (
      <SignInModal
        source={props?.source}
        signUpEventName={props?.signUpEventName || 'Sign Up - Home Page'}
        onClose={props?.onClose}
      />
    ),
    width: { lg: '72rem', md: 'unset' },
    height: { lg: '45rem', md: '100vh' },
    variant: { lg: 'standard', md: 'fullscreen' },
    onClose: props?.onClose,
  });

  return { showModal };
};

export interface UseSignOutProps {
  analyticsEvent: string;
}

export const useSignOut = () => {
  const [, authDispatch] = useAuth();
  const [, cartDispatch] = useCart();
  const [, , removeCookie] = useCookies([AUTH_TOKEN, FIREBASE_TOKEN]);
  const router = useRouter();

  const signOut = async () => {
    await api.auth.logout();

    authDispatch({
      type: 'SET_PROPERTIES',
      data: {
        authenticated: false,
        token: '',
        firebaseToken: '',
        status: 'NOT AUTHENTICATED',
        user: null,
      },
    });

    removeCookie(AUTH_TOKEN, {
      domain: environment.COOKIES_DOMAIN,
      path: '/',
    });

    removeCookie(FIREBASE_TOKEN, {
      path: '/',
      domain: environment.COOKIES_DOMAIN,
    });

    cartDispatch({
      type: 'SET_PROPERTIES',
      data: {
        records: [],
        wishlist: [],
        giftCode: null,
        credit: 0,
        tax: null,
      },
    });

    removeFromLocalStorage(CART_ITEMS);

    removeFromSessionStorage(ANALYTICS_IDENTIFY_REGISTERED);

    resetAnalyticsUser();

    if (router.asPath.includes('/account')) {
      await router.push('/');
    }
  };

  return { signOut };
};

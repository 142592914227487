import { BoxContainer } from 'layout/box-container';
import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { Spacer } from 'layout/spacer';
import { TextInput } from 'core/text-input';
import React from 'react';
import { Formik } from 'formik';
import { SignUpFormProps } from './sign-up-form.props';
import { SignUpFormSchema } from 'modals/sign-in/sign-up-form/sign-up-form.schema';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { DividedContainer } from 'layout/divided-container';
import { StyledBackButtonContainer } from 'modals/sign-in/sign-in.styles';
import { Icon } from 'core/icon/icon';

export const SignUpForm = (props: SignUpFormProps) => {
  const { email, processing, onSubmit, onGoBack, onModalClose } = props;

  return (
    <DividedContainer
      leftContent={
        <>
          <Grid padding="unset" rowGap="s1">
            <Cell>
              <Typography variant="h2" tx="signIn:titleSignUpModal" />
            </Cell>
            <Cell>
              <Typography variant="p2" text={email} color="grey" />
            </Cell>
          </Grid>
          <Button
            tx="signIn:back"
            variant="action"
            icon={<Icon name="arrow-left" color="grey" />}
            color="grey"
            onClick={onGoBack}
          />
        </>
      }
      leftContentProps={{
        direction: 'column',
        justifyContent: 'space-between',
        hidden: { sm: true, md: true },
      }}
      rightContent={
        <Formik
          initialValues={{ firstName: '', lastName: '', password: '' }}
          enableReinitialize={true}
          validationSchema={SignUpFormSchema}
          validateOnChange={true}
          validateOnMount={true}
          onSubmit={values => onSubmit(values)}>
          {props => (
            <form onSubmit={props.handleSubmit}>
              <BoxContainer
                marginBottom="s2"
                hidden={{ lg: true, md: false }}
                padding="unset">
                <Grid padding="unset" rowGap={{ md: 's1' }}>
                  <Cell>
                    <Typography
                      variant="h2"
                      tx="signIn:titleSignUpModal"
                      align="center"
                    />
                  </Cell>
                  <Cell>
                    <Typography
                      variant="p2"
                      text={email}
                      color="grey"
                      align="center"
                    />
                  </Cell>
                </Grid>
              </BoxContainer>
              <FlexContainer
                backgroundColor={{ md: 'white' }}
                borderRadius={{ md: '6px' }}
                direction="column"
                justifyContent={{ lg: 'center', md: 'center' }}
                verticalPadding={{ md: 's4' }}
                horizontalPadding={{ md: 's2' }}>
                <Grid columns={2} padding="unset" columnGap="s1" rowGap="s4">
                  <Cell>
                    <TextInput
                      name="firstName"
                      placeholderTx="signIn:firstName"
                      value={props.values.firstName}
                      error={props.errors.firstName}
                      touched={props.touched.firstName}
                      autoFocus={true}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </Cell>
                  <Cell>
                    <TextInput
                      name="lastName"
                      placeholderTx="signIn:lastName"
                      value={props.values.lastName}
                      error={props.errors.lastName}
                      touched={props.touched.lastName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </Cell>
                  <Cell width={2}>
                    <TextInput
                      name="password"
                      type="password"
                      placeholderTx="signIn:password"
                      value={props.values.password}
                      error={props.errors.password}
                      touched={props.touched.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </Cell>
                </Grid>
                <Spacer height="s4" />
                <Button
                  tx="signIn:next"
                  variant="primary"
                  type="submit"
                  processing={processing}
                  disabled={!props.isValid}
                />
              </FlexContainer>
              <BoxContainer
                marginTop="s4"
                padding="unset"
                textAlign="center"
                hidden={{ lg: true, md: false }}>
                <Typography
                  variant="p3"
                  type="span"
                  tx="signIn:terms"
                  color="grey"
                  align="center"
                />
                <Typography
                  variant="link"
                  fontSize="1.2rem"
                  type="span"
                  tx="signIn:termsLink"
                  color="grey"
                  textStyle="bold"
                  display="inline"
                  href="/legal/terms"
                  onClick={onModalClose}
                />
              </BoxContainer>
              <StyledBackButtonContainer>
                <Icon
                  name="arrow-left"
                  color="grey"
                  cursor="pointer"
                  width="1.8rem"
                  onClick={onGoBack}
                />
              </StyledBackButtonContainer>
            </form>
          )}
        </Formik>
      }
      rightContentProps={{
        direction: 'column',
        justifyContent: {
          lg: 'center',
          md: 'flex-start',
        },
        horizontalPadding: {
          lg: 's5',
          md: 's2',
        },
        paddingTop: '9rem',
        backgroundColor: {
          lg: 'white',
          md: 'cream',
        },
      }}
    />
  );
};

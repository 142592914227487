import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { Spacer } from 'layout/spacer';
import { TextInput } from 'core/text-input';
import React from 'react';
import { Formik } from 'formik';
import { DividedContainer } from 'layout/divided-container';
import { ResetFormProps } from 'modals/sign-in/reset-form/reset-form.props';
import { Cell } from 'layout/cell';
import { Grid } from 'layout/grid';
import { StyledBackButtonContainer } from 'modals/sign-in/sign-in.styles';
import { Icon } from 'core/icon/icon';
import { ResetFormSchema } from 'modals/sign-in/reset-form/reset-form.schema';

export const ResetForm = (props: ResetFormProps) => {
  const { email, onSubmit, onGoBack } = props;

  return (
    <DividedContainer
      leftContent={
        <>
          <Grid padding="unset" rowGap="s1">
            <Cell>
              <Typography variant="h2" tx="signIn:titleResetModal" />
            </Cell>
            <Cell>
              <Typography color="grey" tx="signIn:confirmEmail" />
            </Cell>
          </Grid>
          <Button
            tx="signIn:back"
            variant="action"
            icon={<Icon name="arrow-left" color="grey" />}
            color="grey"
            onClick={onGoBack}
          />
        </>
      }
      leftContentProps={{
        direction: 'column',
        justifyContent: 'space-between',
        hidden: { sm: true, md: true },
      }}
      rightContent={
        <Formik
          initialValues={{ email }}
          enableReinitialize={true}
          validationSchema={ResetFormSchema}
          validateOnMount={true}
          validateOnChange={true}
          onSubmit={values => onSubmit(values)}>
          {props => (
            <form onSubmit={props.handleSubmit}>
              <FlexContainer
                padding="unset"
                direction="column"
                marginBottom="s2"
                hidden={{ lg: true, md: false, sm: false }}>
                <Typography
                  variant="h2"
                  tx="signIn:titleResetModal"
                  align={{ md: 'center' }}
                />
                <Typography
                  variant="p2"
                  tx="signIn:confirmEmail"
                  color="grey"
                  align={{ md: 'center' }}
                />
              </FlexContainer>

              <FlexContainer
                backgroundColor={{ md: 'white' }}
                borderRadius={{ md: '6px' }}
                direction="column"
                justifyContent={{ lg: 'center', md: 'center' }}
                verticalPadding={{ md: 's4' }}
                horizontalPadding={{ md: 's2' }}>
                <TextInput
                  name="email"
                  type="email"
                  placeholderTx="signIn:email"
                  value={props.values.email}
                  error={props.errors.email}
                  touched={props.touched.email}
                  autoFocus={true}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Spacer height="s5" />
                <Button
                  type="submit"
                  tx="signIn:sendReset"
                  variant="primary"
                  disabled={!props.isValid}
                />
              </FlexContainer>
              <StyledBackButtonContainer>
                <Icon
                  name="arrow-left"
                  color="grey"
                  cursor="pointer"
                  width="1.8rem"
                  onClick={onGoBack}
                />
              </StyledBackButtonContainer>
            </form>
          )}
        </Formik>
      }
      rightContentProps={{
        direction: 'column',
        justifyContent: {
          lg: 'center',
          md: 'flex-start',
        },
        horizontalPadding: {
          lg: 's5',
          md: 's2',
        },
        paddingTop: '9rem',
        backgroundColor: {
          lg: 'white',
          md: 'cream',
        },
      }}
    />
  );
};

import { Review } from 'types/review.type';
import { SKU } from 'types/product.type';
import { CartProduct } from 'services/api/cart/cart-api.types';
import range from 'lodash/range';
import { SearchProduct } from 'services/api';
import { v4 as uuidv4 } from 'uuid';

export const convertYotpoReviewsToProductSchemaReviews = (
  reviews: Review[],
) => {
  if (reviews?.length) {
    return reviews.map(review => ({
      author: {
        type: 'Person',
        name: review.user.display_name,
      },
      datePublished: new Date(review.created_at).toDateString(),
      reviewBody: review.content,
      name: review.title,
      reviewRating: {
        ratingValue: String(review.score),
      },
    }));
  }
  return [];
};

export const convertSkuToCartProduct = (
  sku: SKU,
  inStock?: boolean,
): CartProduct => {
  return sku
    ? {
        cartId: uuidv4(),
        productId: sku.id,
        sku: sku.sku,
        price: sku.price,
        inStock: inStock ?? !!sku.stocks,
        size: `${sku.dimension?.height}" tall`,
        color: sku.color.name,
        designerName: sku.designer.name,
        name: sku.fullProductName,
        fullSkuName: sku.fullSkuName,
        thumbnail: sku.photoUrls.thumbnail.url,
        product: {
          id: sku.id,
          name: sku.name,
          stocks: sku.stocks,
          skuPrices: sku.skuPrices,
          status: sku.status,
          skus: sku.skus,
          category: sku.category,
          fullUrlName: sku.fullUrlName,
        },
      }
    : {};
};

export const getProductSkusData = (
  records: SKU[],
  productId: number,
  variant?: string,
): { [sku: string]: SKU } => {
  return records
    .filter(
      record =>
        record.id === productId &&
        (!variant || (variant && record.variant === variant)),
    )
    .reduce(
      (accumulator, current) =>
        Object.assign({}, accumulator, {
          [current.sku]: current,
        }),
      {},
    );
};

export const getProductWithSkusData = (
  skus: SKU[],
  productId: number,
  variant?: string,
): SKU => {
  const sku = skus.find(
    item =>
      item.id === productId &&
      (!variant || (variant && item.variant === variant)),
  );

  if (sku) {
    return Object.assign({}, sku, {
      skusData: getProductSkusData(skus, sku.id, variant),
    });
  }

  return null;
};

export const extractAmmunitionProducts = (skus: SKU[]): SKU[] => {
  // ammunition products Ids go from 10061 to 10070
  return [
    ...range(10061, 10071)
      .filter(item => item !== 10067)
      .map(id => getProductWithSkusData(skus, id)),
    getProductWithSkusData(skus, 10067, 'left'),
    getProductWithSkusData(skus, 10067, 'right'),
  ];
};

export const extractReachProducts = (skus: SKU[]): SKU[] => {
  return [10079, 10075, 10080]
    .map(id => getProductWithSkusData(skus, id))
    .filter(sku => !!sku);
};

export const extractProductPageUrlFromSku = (sku: SKU): string => {
  return `/products/${sku.id}/${sku.fullUrlName}/${sku.sku}`.toLowerCase();
};

export const extractAnalyticsProductDataFromSku = (sku: SKU) => {
  if (!sku) {
    return {};
  }

  return {
    brand: sku?.designer?.name,
    category: sku?.category,
    currency: 'USD',
    name: sku?.name,
    price: sku?.price / 100,
    product_id: sku?.sku,
    quantity: 1,
    sku: sku?.sku,
  };
};

export const convertSearchProductsToCartProducts = (
  searchProducts: SearchProduct[],
): SKU[] => {
  return searchProducts?.length
    ? searchProducts.map(
        (searchProduct: SearchProduct) =>
          (({
            ...searchProduct,
            sku: searchProduct.defaultSku,
            fullProductName: searchProduct.fullName,
            designer: { name: searchProduct.designerName },
            photoUrls: {
              photos: {
                product: [
                  {
                    desktop: searchProduct.thumbnail,
                    mobile: searchProduct.thumbnail,
                    fileName: '',
                  },
                ],
              },
            },
          } as unknown) as SKU),
      )
    : [];
};
